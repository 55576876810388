/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;
  /*========== Colors ==========*/
  /* #0000FF --- blue */
  /* #6c63ff ---- dark blue */
  /* #3955fd --primary color */
  --hue: 45;
  --sat: 98%;
  /* --first-color: hsl(var(--hue), var(--sat), 60%);
  --first-color-light: hsl(var(--hue), var(--sat), 85%);
  --first-color-lighten: hsl(var(--hue), var(--sat), 80%);
  --first-color-alt: hsl(var(--hue), var(--sat), 53%); */
  --first-color: hsl(210, var(--sat), 60%);
  --first-color-light: hsl(210, var(--sat), 85%);
  --first-color-lighten: hsl(210, var(--sat), 80%);
  --first-color-alt: hsl(210, var(--sat), 53%);

  --title-color: hsl(var(--hue), 4%, 15%);
  --text-color: hsl(var(--hue), 4%, 35%);
  --text-color-light: hsl(var(--hue), 4%, 65%);
  --body-color: hsl(var(--hue), 0%, 100%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue), 4%, 85%);
  --scroll-thumb-color: hsl(var(--hue), 4%, 75%);
  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  --biggest-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  /*========== Font weight ==========*/
  --font-semi-bold: 600;
  --font-bold: 700;
  /*========== Margenes ==========*/
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 2.5rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
/* Hide arrows in Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*=============== BASE ===============*/
*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  transition: 0.5s;
}

h1,
h2,
h3 {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  line-height: 1.5;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 0.5rem 0 1rem;
}

.section__title,
.section__title-center {
  font-size: var(--h2-font-size);
  color: var(--title-color);
  text-align: center;
  margin-bottom: var(--mb-1);
  margin-top: var(--mb-1);
}

.svg__color {
  fill: var(--first-color);
}

.svg__blob {
  fill: var(--first-color-light);
}

.svg__img {
  width: 300px;
  justify-self: center;
}

/*=============== LAYOUT ===============*/
.container {
  max-width: 968px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/*=============== HEADER ===============*/
.header {
  width: 100%;
  background-color: var(--body-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  transition: 0.5s;
}

/*=============== NAV ===============*/
.nav {
  /* height: var(--header-height); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    background-color: var(--container-color);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    padding: 2.5rem 0;
    width: 90%;
    top: -100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: 0.4s;
    border-radius: 2rem;
    z-index: var(--z-fixed);
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
}

.nav__link,
.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  padding: 5px 0;
}

.nav__toggle {
  font-size: 1.3rem;
  cursor: pointer;
}
.hero__page {
  display: grid;
}
.carousel-img {
  width: 100% !important;
}

/* Show menu */
.show-menu {
  top: calc(var(--header-height) + 1rem);
}

/* Active link */
.active-link {
  position: relative;
}

.active-link::before {
  content: "";
  position: absolute;
  bottom: -0.75rem;
  left: 45%;
  width: 5px;
  height: 5px;
  background-color: var(--title-color);
  border-radius: 50%;
}

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/*=============== HOME ===============*/
.home__container {
  row-gap: 3rem;
}

.home__title {
  font-size: 2rem;
  font-weight: var(--font-bold);
  margin-bottom: var(--mb-0-75);
  margin-top: var(--mb-0-75);
}

.home__description {
  margin-bottom: var(--mb-2);
}

/*=============== BUTTONS ===============*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #1a1a1a;
  padding: 0.6rem 1.5rem;
  border-radius: 5px;
  font-weight: var(--font-semi-bold);
  transition: 0.3s;
  color: #fff;
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button__header {
  display: none;
}
.button__login {
  display: block;
}

.button-link {
  background: none;
  padding: 0;
  color: var(--title-color);
}

.button-link:hover {
  background-color: transparent;
}

.button-flex {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  padding: 0.75rem 1rem;
}

.button__icon {
  font-size: 1.5rem;
}

/*=============== ABOUT ===============*/
.about__container {
  gap: 2.5rem;
}

.about__data {
  text-align: start;
}

/*=============== SECURITY ===============*/
.security__container {
  gap: 2.5rem;
}

.security__data {
  text-align: center;
}

/*=============== SERVICES ===============*/
.services__container {
  padding-top: 1rem;
}

.services__data {
  display: grid;
  row-gap: 1rem;
  background-color: var(--container-color);
  box-shadow: 0px 2px 6px hsla(var(--hue), 100%, 15%, 0.15);
  padding: 2rem 1.5rem;
  border-radius: 1rem;
  text-align: center;
}

.services__img {
  width: 135px;
  justify-self: center;
  margin-bottom: var(--mb-0-5);
}

/*=============== APP ===============*/
.app__container {
  gap: 2.5rem;
}

.app__data {
  text-align: center;
}

.app__description {
  margin-bottom: var(--mb-1-5);
}

.app__buttons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  gap: 0.5rem;
}

/*=============== FOOTER ===============*/
.footer {
  background-color: var(--first-color-lighten);
  padding-bottom: 2rem;
}

.footer__container {
  row-gap: 2rem;
}

.footer__logo,
.footer__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-75);
}

.footer__logo {
  display: inline-block;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.footer__description,
.footer__link {
  font-size: var(--small-font-size);
}

.footer__links {
  display: grid;
  row-gap: 0.5rem;
}

.footer__link {
  color: var(--title-color);
}

.footer__social {
  display: flex;
  column-gap: 1.5rem;
}

.footer__social-link {
  font-size: 1.25rem;
  color: var(--title-color);
}

.footer__copy {
  margin-top: 3rem;
  text-align: center;
  font-size: var(--smaller-font-size);
  color: var(--text-color);
}

/*=============== SCROLL UP ===============*/
.scrollup {
  position: fixed;
  background: var(--first-color);
  right: 1rem;
  bottom: -20%;
  display: inline-flex;
  padding: 0.3rem;
  border-radius: 0.25rem;
  z-index: var(--z-tooltip);
  opacity: 0.8;
  transition: 0.4s;
}

.scrollup:hover {
  background-color: var(--first-color);
  opacity: 1;
}

.scrollup__icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

/* Show Scroll Up*/
.show-scroll {
  bottom: 3rem;
}

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
  background-color: var(--scroll-bar-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */
@media screen and (max-width: 360px) {
  .svg__img {
    width: 100%;
  }
  .section {
    padding: 3.5rem 0 1rem;
  }
  .services__img {
    width: 100px;
  }
  .app__buttons {
    grid-template-columns: max-content;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .svg__img {
    width: 100%;
  }
  .section__title-center {
    text-align: initial;
  }
  .home__container,
  .about__container,
  .security__container,
  .services__container,
  .app__container,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .home__img {
    order: 1;
  }
  .home__container,
  .about__container,
  .security__container,
  .app__container {
    align-items: center;
  }
  .about__data,
  .security__data,
  .app__data {
    text-align: initial;
  }
  .about__img,
  .app__img {
    order: -1;
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }
  .section {
    padding: 3rem 0 2rem;
  }
  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }
  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
  .nav__toggle {
    display: none;
  }
  .change-theme {
    position: initial;
  }
  .home__container {
    padding: 3rem 0 2rem;
  }
  .app__buttons {
    justify-content: initial;
  }
  .hero__page {
    display: flex;
  }
  .carousel-img {
    width: 50% !important;
  }
}

/* For large devices */
@media screen and (min-width: 968px) {
  .button__header {
    display: block;
  }
  .button__login {
    display: none;
  }
  .svg__img {
    width: 470px;
  }
  .about__container,
  .security__container,
  .app__container {
    column-gap: 6rem;
  }
  .services__container {
    grid-template-columns: repeat(3, 1fr);
  }
  .footer__container {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }
  .footer__social {
    align-items: flex-start;
  }
  .footer__social-link {
    font-size: 1.45rem;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  .home__img {
    width: 540px;
  }
  .home__description {
    padding-right: 5rem;
  }
  .footer__container {
    column-gap: 3rem;
  }
  .scrollup {
    right: 2rem;
  }
}

/* *****tracking */
.tracking {
  max-width: 80%;
}
.tracking .input-box {
  position: relative;
  height: 76px;
  max-width: 900px;
  width: 100%;
  background: #fff;
  border: 1px solid #ecebeb;
  margin: 0 20px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.tracking .input-box i,
.tracking .input-box .button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tracking .input-box i {
  left: 20px;
  font-size: 30px;
  color: #707070;
}
.tracking .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: 400;
  border: none;
  padding: 0 155px 0 65px;
  background-color: transparent;
  font-family: var(--body-font) !important;
  margin-top: 25px;
}
.tracking .input-box .button {
  right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  background-color: rgb(108, 99, 255);
  cursor: pointer;
}
.tracking .input-box .button:active {
  transform: translateY(-50%) scale(0.98);
}
/* Responsive */
@media screen and (max-width: 500px) {
  .tracking {
    max-width: 100%;
  }
  .tracking .input-box {
    height: 66px;
    margin: 0 8px;
  }
  .tracking .input-box i {
    left: 12px;
    font-size: 25px;
  }
  .tracking .input-box input {
    padding: 0 112px 0 50px;
    font-size: 13px;
  }
  .tracking .input-box .button {
    right: 12px;
    font-size: 14px;
    padding: 8px 18px;
  }
}

/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/

.service {
  background: var(--cultured);
  padding-block: var(--section-padding);
}

.service-banner {
  margin-bottom: 30px;
}

.service-content:first-of-type {
  padding-bottom: 50px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.05);
  margin-bottom: 50px;
}

.service .section-title {
  margin-bottom: 30px;
}

.service-item {
  background: var(--white);
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 20px;
  box-shadow: 0 5px 25px 10px hsla(0, 0%, 0%, 0.02);
  border-radius: var(--radius-8);
}

.service-item:not(:last-child) {
  margin-bottom: 20px;
}

.service-item-icon {
  background: var(--cultured);
  color: var(--red-crayola);
  min-width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transition: var(--transition);
}

.service-item-icon ion-icon {
  --ionicon-stroke-width: 50px;
}

.service-item:hover .service-item-icon {
  background: var(--red-crayola);
  color: var(--white);
}

@media (min-width: 768px) {
  /**
   * SERVICE
   */

  .service-item:not(:last-child) {
    margin-bottom: 30px;
  }

  .service-list {
    padding-right: 80px;
  }

  .service-item:nth-child(even) {
    transform: translateX(80px);
  }
}
@media (min-width: 992px) {
  /**
   * SERVICE
   */

  .service .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 100px 40px;
  }

  .service-banner {
    margin-bottom: 0;
  }

  .service-content:first-of-type {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }

  .service-list {
    padding-right: 50px;
  }

  .service-item:nth-child(even) {
    transform: translateX(50px);
  }

  .service-item-icon {
    min-width: 40px;
    height: 40px;
    font-size: 1.125rem;
  }

  .service-banner:last-of-type {
    order: 1;
  }
}
@media (min-width: 1200px) {
  /**
   * SERVICE
   */

  .service .container {
    column-gap: 60px;
  }

  .service .section-title {
    margin-bottom: 50px;
  }
}

/*=============== LOGIN FORM ===============*/
.login__content,
.login__form,
.login__inputs {
  display: grid;
  font-family: var(--body-font);
}
.login__content {
  position: relative;
  height: 100vh;
  align-items: center;
}
.login__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.login__form {
  position: relative;
  background-color: hsla(244, 16%, 92%, 0.6);
  border: 2px solid hsla(244, 16%, 92%, 0.75);
  margin-inline: 1.5rem;
  row-gap: 1.25rem;
  backdrop-filter: blur(20px);
  padding: 2rem;
  border-radius: 1rem;
}
.login__title {
  color: var(--title-color);
  font-size: var(--h2-font-size);
  margin-bottom: 0.5rem;
}
.login__title span {
  color: var(--first-color);
}
.login__description {
  font-size: var(--small-font-size);
}
.login__inputs {
  row-gap: 0.75rem;
  margin-bottom: 0.5rem;
  font-family: var(--body-font);
}
.login__label {
  display: block;
  color: var(--title-color);
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 0.25rem;
}
.login__input {
  width: 100%;
  padding: 14px 12px;
  border-radius: 6px;
  border: 2px solid var(--text-color);
  background-color: hsla(244, 16%, 92%, 0.6);
  color: var(--title-color);
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  transition: border 0.4s;
  font-family: var(--body-font);
}
.login__input::placeholder {
  color: var(--text-color);
  font-family: var(--body-font);
}
.login__box {
  position: relative;
}
.login__box .login__input {
  padding-right: 36px;
  font-family: var(--body-font);
}
.login__eye {
  width: max-content;
  height: max-content;
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 1.25rem;
  cursor: pointer;
}
.login__check {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}
.login__check-input {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--text-color);
  background-color: hsla(244, 16%, 92%, 0.2);
  border-radius: 0.25rem;
}
.login__check-input:checked {
  background: var(--first-color);
}
.login__check-input:checked::before {
  content: "✔";
  display: block;
  color: #fff;
  font-size: 0.75rem;
  transform: translate(1.5px, -2.5px);
}
.login__check-label {
  font-size: var(--small-font-size);
}
.login__buttons {
  display: flex;
  column-gap: 0.75rem;
}
.login__button {
  width: 100%;
  padding: 14px 2rem;
  border-radius: 6px;
  background: linear-gradient(180deg, var(--first-color), var(--second-color));
  color: #fff;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  box-shadow: 0 6px 24px hsla(202, 75%, 48%, 0.5);
  margin-bottom: 1rem;
  cursor: pointer;
}
.login__button-ghost {
  background: hsla(244, 16%, 92%, 0.6);
  border: 2px solid var(--first-color);
  color: var(--first-color);
  box-shadow: none;
}
.login__forgot {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--first-color);
  text-decoration: none;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 360px) {
  .login__buttons {
    flex-direction: column;
  }
}
/* For medium devices */
@media screen and (min-width: 576px) {
  .login__form {
    width: 450px;
    justify-self: center;
  }
}
/* For large devices */
@media screen and (min-width: 1064px) {
  .login__content {
    width: 1024px;
    height: 600px;
  }
  .login__img {
    border-radius: 3.5rem;
  }
  .login__form {
    justify-self: flex-end;
    margin-right: 4.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .login__content {
    height: 700px;
  }
  .login__form {
    row-gap: 2rem;
    padding: 3rem;
    border-radius: 1.25rem;
    border: 2px solid hsla(244, 16%, 92%, 0.75);
  }
  .login__description,
  .login__label,
  .login__button {
    font-size: var(--normal-font-size);
  }
  .login__inputs {
    row-gap: 1.25rem;
    margin-bottom: 0.75rem;
    font-family: var(--body-font);
  }
  .login__input {
    border: 2px solid var(--text-color);
    padding: 1rem;
    font-size: var(--small-font-size);
    font-family: var(--body-font);
  }

  .login__button {
    padding-block: 1rem;
    margin-bottom: 1.25rem;
  }
  .login__button-ghost {
    border: 2px solid var(--first-color);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* *******faq******** */
/*=============== QUESTIONS ===============*/
.questions {
  background-color: var(--first-color-lighten);
}

.questions__container {
  gap: 1.5rem;
  padding: 1.5rem 0;
}

.questions__group {
  display: grid;
  row-gap: 1.5rem;
}

.questions__item {
  background-color: var(--container-color);
  border-radius: 0.25rem;
}

.questions__item-title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.questions__icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.questions__description {
  font-size: var(--smaller-font-size);
  padding: 0 1.25rem 1.25rem 2.5rem;
}

.questions__header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
}

.questions__content {
  overflow: hidden;
  height: 0;
}

.questions__item,
.questions__header,
.questions__item-title,
.questions__icon,
.questions__description,
.questions__content {
  transition: 0.3s;
}

.questions__item:hover {
  box-shadow: 0 2px 8px hsla(var(--hue), 4%, 15%, 0.15);
}

/*Rotate icon, change color of titles and background*/
.accordion-open .questions__header,
.accordion-open .questions__content {
  background-color: var(--first-color);
}

.accordion-open .questions__item-title,
.accordion-open .questions__description,
.accordion-open .questions__icon {
  color: #fff;
}

.accordion-open .questions__icon {
  transform: rotate(45deg);
}

/* logo stylings*/
.footer__logo {
  max-width: 50%;
}

@media screen and (min-width: 576px) {
  .footer__logo {
    max-width: 100%;
  }
}

/* not needed */
.tasks-list {
  list-style-type: none;
  padding: 0;
}

.tasks-item {
  margin-bottom: 20px;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* login... */

/*===== LOGIN =====*/
.login {
  display: grid;
  grid-template-columns: 100%;
  /* height: 100vh; */
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 40px;
}

.login__content {
  display: grid;
}

.login__img {
  justify-self: center;
}

.login__img img {
  width: 310px;
  margin-top: 1.5rem;
}

.login__forms {
  position: relative;
  height: 368px;
}

.login__registre,
.login__create {
  position: absolute;
  bottom: -3rem;
  width: 100%;
  background-color: var(--first-color-lighten);
  padding: 2rem 1rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(35, 0, 77, 0.2);
  animation-duration: 0.4s;
  animation-name: animate-login;
}

@keyframes animate-login {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.login__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

.login__box {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 0.5rem;
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 0.5rem;
}

.login__icon {
  font-size: 1.5rem;
  color: var(--first-color);
  margin-top: 10px;
}

.login__input {
  border: none;
  outline: none;
  color: var(--first-color-dark);
}

.login__input::placeholder {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  color: var(--first-color-light);
}

.login__forgot {
  display: block;
  width: max-content;
  margin-left: auto;
  margin-top: 0.5rem;
  font-size: var(--small-font-size);
  font-weight: 600;
  color: #fff;
}

.login__button {
  display: block;
  padding: 1rem;
  margin: 2rem 0;
  background-color: var(--first-color);
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-radius: 0.5rem;
  transition: 0.3s;
}

.login__button:hover {
  background-color: var(--first-color-dark);
}

.login__account,
.login__signin,
.login__signup {
  font-weight: 600;
  font-size: var(--small-font-size);
}

.login__account {
  color: var(--first-color-dark);
}

.login__signin,
.login__signup {
  color: var(--first-color);
  cursor: pointer;
}

.login__social {
  margin-top: 2rem;
}

.login__social-icon {
  font-size: 1.5rem;
  color: var(--first-color-dark);
  margin: 0 1.5rem;
}

/*Show login*/
.block {
  display: block;
}

/*Hidden login*/
.none {
  display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
  .login__forms {
    width: 348px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px) {
  .login {
    height: 100vh;
    overflow: hidden;
  }

  .login__content {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
  }

  .login__img {
    display: flex;
    width: 600px;
    height: 588px;
    background-color: var(--first-color-lighten);
    border-radius: 1rem;
    padding-left: 18rem;
  }

  .login__img img {
    width: 390px;
    margin-top: 0;
  }

  .login__registre,
  .login__create {
    left: -11rem;
  }

  .login__registre {
    bottom: -5.2rem;
  }

  .login__create {
    bottom: -5.5rem;
  }
}

/* tracking list and component */
.tracking__ol {
  margin-inline-start: 0.75rem;
  border-left: 1px solid #e5e7eb;
  position: relative;
  list-style: none;
}

.tracking__li {
  margin-bottom: 2.5rem;
  margin-inline-start: 1.5rem;
  position: relative;
}
.tracking__li span {
  position: absolute;
  inset-inline-start: -2.4rem;
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  border-radius: 9999px;
  box-shadow: 0 0 0 2px #ffffff;
}
.tracking__li h4 {
  margin-bottom: 0.125rem;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
}
.tracking__li p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #6b7280;
}

/* whatsaap */
.whatsapp-button {
  position: fixed;
  background-color: #25d366;
  bottom: 20px;
  right: 1rem;
  border-radius: 0.25rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  opacity: 0.8;
  transition: bottom 0.4s ease;
}

.whatsapp-button:hover {
  background-color: #25d366;
  opacity: 1;
}

.whatsapp-button i {
  font-size: 1.25rem;
  color: white;
}
.whatsapp-button.scrolled {
  bottom: 80px;
}

@media screen and (min-width: 1024px) {
  .whatsapp-button {
    right: 2rem;
  }
}

/* carousel */
/* Hero.css */
.home__container {
  position: relative;
  overflow: hidden;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel__inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel__slide {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.carousel__slide img {
  width: 100%;
  height: auto;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(0 0 0 / 28%);
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
}

.carousel__button--prev {
  left: 10px;
}

.carousel__button--next {
  right: 10px;
}

.carousel__dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.carousel__dot {
  width: 15px;
  height: 15px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.carousel__dot.active {
  background-color: #333;
}

.carousel__dot.active::after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  border: 2px solid #333;
  border-radius: 50%;
  transition: all 0.3s ease;
}

/* terms and conditions */
.terms-and-conditions {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin: 100px auto 8px;
}
