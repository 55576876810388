/*-----------------------------------*\
  # style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {
  /**  
       * colors
       */

  --raisin-black-2: hsl(245, 16%, 16%);
  --raisin-black-1: hsl(244, 17%, 19%);
  --majorelle-blue: hsl(210, var(--sat), 60%);
  --ghost-white-1: hsl(240, 100%, 99%);
  --ghost-white-2: hsl(228, 50%, 96%);
  --white-opacity: hsla(0, 0%, 100%, 0.5);
  --independence: hsl(245, 17%, 27%);
  --lavender-web: hsl(247, 69%, 95%);
  --eerie-black: hsl(210, 11%, 15%);
  --cool-gray: hsl(244, 17%, 61%);
  --sapphire: hsl(211, 100%, 35%);
  --white: hsl(0, 0%, 100%);

  /**
       * typography
       */

  --ff-quicksand: "Quicksand", sans-serif;
  --ff-mulish: "Mulish", sans-serif;

  --fs-1: 36px;
  --fs-2: 28px;
  --fs-3: 20px;
  --fs-4: 17px;
  --fs-5: 16px;
  --fs-6: 15px;
  --fs-7: 14px;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
       * transition
       */

  --transition: 0.25s ease;

  /**
       * spacing
       */

  --section-padding: 80px;
}

/*-----------------------------------*\
      #RESET
    \*-----------------------------------*/

.contacts__section input,
.contacts__section textarea {
  border: none;
  font: inherit;
  width: 100%;
}

/*-----------------------------------*\
      #REUSED STYLE
    \*-----------------------------------*/

.contacts__section .btn {
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  padding: 15px 30px;
  border-radius: 4px;
  transition: var(--transition);
}

.contacts__section .btn:is(:hover, :focus) {
  transform: translateY(-2px);
}

.contacts__section .btn-primary {
  background: var(--majorelle-blue);
  color: var(--white);
}

.contacts__section .btn-primary:is(:hover, :focus) {
  --majorelle-blue: hsl(245, 67%, 55%);
  box-shadow: 0 3px 10px hsla(245, 67%, 59%, 0.5);
}

.contacts__section .btn-outline {
  border: 1px solid var(--majorelle-blue);
  color: var(--majorelle-blue);
}

.contacts__section .btn-outline:is(:hover, :focus) {
  background: var(--majorelle-blue);
  color: var(--white);
  box-shadow: 0 3px 10px hsla(245, 67%, 59%, 0.5);
}

.contacts__section .btn-secondary {
  background: hsla(245, 67%, 59%, 0.15);
  color: var(--majorelle-blue);
}

/*-----------------------------------*\
      #CONTACT
    \*-----------------------------------*/

.contact {
  padding-block: var(--section-padding);
  background: var(--ghost-white-1);
}

.contact .section-title {
  margin-bottom: 15px;
}

.contact .section-text {
  margin-bottom: 60px;
}

.contact-form {
  margin-bottom: 50px;
}

.input-wrapper {
  margin-bottom: 10px;
}

.contact label {
  color: var(--independence);
  font-weight: var(--fw-500);
  margin-bottom: 10px;
}

.contact .input-field {
  background: transparent;
  color: var(--independence);
  font-size: var(--fs-7);
  padding: 10px 15px;
  border: 1px solid hsla(244, 17%, 67%, 0.3);
  border-radius: 4px;
}

.contact .input-field:focus {
  outline: none;
  background: var(--ghost-white-2);
}

.contact .input-field::placeholder {
  color: var(--cool-gray);
}

textarea.input-field {
  margin-bottom: 20px;
  resize: vertical;
  min-height: 50px;
  height: 100px;
  max-height: 200px;
}

.contact .btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contact .btn-primary ion-icon {
  --ionicon-stroke-width: 40px;
}

.contact-list li:not(:last-child) {
  margin-bottom: 25px;
}

.contact-link {
  color: var(--cool-gray);
  font-weight: var(--fw-500);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.contact-link ion-icon {
  font-size: 20px;
  --ionicon-stroke-width: 30px;
}

.contact-link :is(span, address) {
  width: calc(100% - 25px);
}

.contact-link address {
  font-style: normal;
}

/*-----------------------------------*\
      #MEDIA QUERIES
    \*-----------------------------------*/

/**
     * responsive for larger than 768px screen
     */

@media (min-width: 768px) {
  /**
       * CONTACT
       */

  .contact-form .wrapper-flex {
    display: flex;
    gap: 30px;
  }

  .wrapper-flex .input-wrapper {
    width: 50%;
  }
}

/**
     * responsive for larger than 992px screen
     */

@media (min-width: 992px) {
  /**
       * CONTACT
       */

  .contact-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    align-items: center;
  }

  .contact-form {
    margin-bottom: 0;
  }
}

/* select */
.custom-select-container {
  position: relative;
  width: 100%;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  outline: none;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.custom-select option {
  padding: 10px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
}

.custom-select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #333;
  pointer-events: none;
}
