.mainFaqContainer p {
  color: var(--secondary-gray);
}

.mainFaqContainer .faqcontainer .smallcolumn > p {
  margin-bottom: 10px;
  margin-right: 20px;
  color: var(--footer-links);
}

.mainFaqContainer .faqcontainer .listoquestion a {
  font-size: 15px;
  display: block !important;
  text-decoration: none;
  color: var(--footer-links);
  margin-bottom: 10px;
  text-transform: lowercase;
}
.mainFaqContainer .faqcontainer .largecolumn p {
  margin-bottom: 50px;
}
.mainFaqContainer .faqcontainer .largecolumn h6 {
  font-weight: 900;
  letter-spacing: 2.2px;
  margin-bottom: 3px;
}
@media (max-width: 1024px) {
  .listoquestion {
    margin-left: 10px;
    margin-right: 10px;
  }
}
