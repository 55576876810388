/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {
  /**
     * colors
     */

  --imperial-red_12: hsla(357, 86%, 57%, 0.12);
  --pacific-blue_12: hsla(188, 78%, 41%, 0.12);
  --davys-gray_12: hsla(210, 9%, 31%, 0.12);
  --imperial-red: hsl(357, 86%, 57%);
  --sonic-sliver: hsl(0, 0%, 47%);
  --pacific-blue: hsl(188, 78%, 41%);
  --blue-ryb_12: hsla(220, 100%, 50%, 0.12);
  --space-cadet: hsl(220, 41%, 20%);
  --eerie-black: hsl(214, 10%, 13%);
  --davys-gray: hsl(210, 9%, 31%);
  --emerald_12: hsla(144, 62%, 53%, 0.12);
  --cool-gray: hsl(225, 11%, 59%);
  --cultured: hsl(225, 20%, 96%);
  --blue-ryb: hsl(220, 100%, 50%);
  --black_08: hsla(0, 0%, 0%, 0.06);
  --black_12: hsla(0, 0%, 0%, 0.12);
  --coral_12: hsla(15, 100%, 65%, 0.12);
  --sunglow: hsl(44, 100%, 61%);
  --emerald: hsl(144, 62%, 53%);
  --onyx-2: hsl(210, 10%, 23%);
  --coral: hsl(15, 100%, 65%);
  --white: hsl(0, 0%, 100%);
  --onyx: hsl(207, 8%, 21%);

  /**
     * typography
     */

  --ff-vietnam: "Be Vietnam Pro", sans-serif;

  --fs-1: 1.563rem;
  --fs-2: 1.5rem;
  --fs-3: 1.25rem;
  --fs-4: 1.078rem;
  --fs-5: 1rem;
  --fs-6: 0.938rem;
  --fs-7: 0.875rem;
  --fs-8: 0.844rem;
  --fs-9: 0.813rem;
  --fs-10: 0.769rem;

  --fw-500: 500;
  --fw-600: 600;

  /**
     * transition
     */

  --transition: 0.25s ease;
  --cubic-out: cubic-bezier(0.45, 0.85, 0.5, 1);
  --cubic-in: cubic-bezier(0.5, 0, 0.5, 0.95);

  /**
     * radius
     */

  --radius-6: 6px;

  /**
     * shadow
     */

  --shadow-1: 0 12px 20px hsla(210, 10%, 23%, 0.07);
  --shadow-2: 0 2px 10px hsla(0, 0%, 0%, 0.04);
  --shadow-3: 0 2px 20px var(--black_08);
}
.admin {
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  a,
  img,
  span,
  data,
  time,
  input,
  button,
  span.icon {
    display: block;
  }

  button {
    font: inherit;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
  }

  html {
    font-family: var(--ff-vietnam);
    scroll-behavior: smooth;
  }

  body {
    padding-block-start: 72px;
    background: var(--cultured);
  }

  :focus-visible {
    outline: 2px solid var(--onyx);
    outline-offset: 1px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: hsl(0, 0%, 95%);
  }

  ::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 80%);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: hsl(0, 0%, 70%);
  }

  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  .home-icon {
    display: block;
  }
  .container {
    padding-inline: 15px;
  }

  .icon-box {
    font-variation-settings: "wght" 300;
  }

  .icon-box .icon {
    font-size: 22px;
  }

  .h2,
  .h3 {
    color: var(--onyx-2);
    font-weight: var(--fw-600);
  }

  .h2 {
    font-size: var(--fs-3);
  }

  .card {
    background: var(--white);
    position: relative;
    padding: 24px;
    border-radius: var(--radius-6);
    box-shadow: var(--shadow-2);
  }

  .card-menu-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    color: var(--cool-gray);
    padding: 6px;
    border-radius: var(--radius-6);
  }

  .card-menu-btn:is(:hover, :focus) {
    background: var(--black_08);
  }

  .ctx-menu {
    background: var(--white);
    position: absolute;
    top: 55px;
    right: 16px;
    width: 180px;
    padding: 10px 5px;
    box-shadow: var(--shadow-1);
    border-radius: var(--radius-6);
  }

  .ctx-menu2 {
    background: var(--white);
    position: absolute;
    top: 65px;
    right: 8px;
    width: 90%;
    max-width: 440px;
    padding: 10px 5px;
    box-shadow: var(--shadow-1);
    border-radius: var(--radius-6);
    z-index: 9999999;
    max-height: 60vh;
    overflow-y: auto;
  }

  .ctx-menu-btn {
    color: var(--color, var(--sonic-sliver));
    font-size: var(--fs-6);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 5px 25px;
    border-radius: var(--radius-6);
  }

  .ctx-menu-btn:is(:hover, :focus) {
    color: var(--hover-color, var(--eerie-black));
    background: var(--black_08);
  }

  .ctx-menu-btn.red {
    --hover-color: var(--imperial-red);
    --color: var(--imperial-red);
  }

  .divider {
    height: 1px;
    background: var(--bg, var(--black_08));
    margin-block: var(--mb, 8px);
  }

  .card-divider {
    --bg: var(--black_12);
    --mb: 25px;
  }

  .section-title-wrapper {
    padding-block: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .section-title {
    color: var(--onyx);
    font-size: var(--fs-5);
    font-weight: var(--fw-600);
  }

  .btn {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: var(--fs-9);
    font-weight: var(--fw-600);
    padding: 8px 16px;
    border-radius: var(--radius-6);
  }

  .btn .icon {
    font-size: 16px;
    font-variation-settings: "wght" 400;
  }

  .btn-link {
    color: var(--blue-ryb);
  }

  .btn-link:is(:hover, :focus) {
    background: var(--blue-ryb_12);
  }

  .btn-primary {
    color: var(--blue-ryb);
    border: 1px solid var(--blue-ryb);
    transition: var(--transition);
  }

  .btn-primary:is(:hover, :focus) {
    background: var(--blue-ryb);
    color: var(--white);
    box-shadow: 0 10px 10px -8px var(--blue-ryb);
  }

  .card-badge {
    background: var(--bg, var(--davys-gray_12));
    color: var(--color, var(--davys-gray));
    font-size: var(--fs-10);
    font-weight: var(--fw-600);
    width: max-content;
    padding: 3px 8px;
    border-radius: var(--radius-6);
    cursor: pointer;
  }

  .card-badge.blue {
    --bg: var(--blue-ryb_12);
    --color: var(--blue-ryb);
  }

  .card-badge.orange {
    --bg: var(--coral_12);
    --color: var(--coral);
  }

  .card-badge.cyan {
    --bg: var(--pacific-blue_12);
    --color: var(--pacific-blue);
  }

  .card-badge.red {
    --bg: var(--imperial-red_12);
    --color: var(--imperial-red);
  }

  .card-badge.green {
    --bg: var(--emerald_12);
    --color: var(--emerald);
  }

  .card-badge.radius-pill {
    border-radius: 50px;
  }

  /*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/

  .header {
    background: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    padding-block: 20px;
    overflow: hidden;
    transition: 0.3s var(--cubic-in);
    box-shadow: var(--shadow-3);
    z-index: 1;
  }

  .header.active {
    height: 370px;
    transition: 0.5s var(--cubic-out);
  }

  .header > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: var(--fs-1);
    font-weight: var(--fw-600);
    color: var(--space-cadet);
  }

  .menu-toggle-btn .icon {
    font-size: 28px;
  }

  .navbar {
    position: absolute;
    top: 72px;
    left: 0;
    width: 100%;
    background: var(--white);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s var(--cubic-out);
  }

  .header.active .navbar {
    opacity: 1;
    visibility: visible;
  }

  .navbar-list {
    padding-inline: 15px;
    margin-bottom: 15px;
  }

  .navbar-link {
    color: var(--onyx);
    font-size: var(--fs-6);
    font-weight: var(--fw-500);
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border-radius: var(--radius-6);
  }

  :is(.navbar-link, .notification, .header-profile):is(:hover, :focus) {
    background: var(--black_08);
  }

  .navbar-link.active {
    color: var(--blue-ryb);
  }

  .user-action-list {
    padding-inline: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }

  .notification {
    color: var(--onyx);
    padding: 8px;
    border-radius: var(--radius-6);
  }

  .header-profile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    padding-right: 15px;
    border-radius: var(--radius-6);
  }

  .profile-avatar {
    overflow: hidden;
    border-radius: var(--radius-6);
  }

  .header :is(.profile-title, .profile-subtitle) {
    font-size: var(--fs-9);
  }

  .header .profile-title {
    color: var(--onyx);
    margin-bottom: 2px;
    font-weight: var(--fw-600);
  }

  .header .profile-subtitle {
    color: var(--cool-gray);
    font-weight: var(--fw-500);
  }

  /*-----------------------------------*\
    #HOME
  \*-----------------------------------*/

  .article.container {
    padding: 20px 14px;
    margin-top: 20px;
  }

  .home {
    display: grid;
    gap: 25px;
  }

  .article-title {
    margin-bottom: 10px;
  }

  .article-subtitle {
    color: var(--davys-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-500);
    margin-bottom: 25px;
  }

  .profile-card-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px;
  }

  .card-avatar {
    overflow: hidden;
    border-radius: var(--radius-6);
  }

  .card-title {
    color: var(--onyx);
    font-weight: var(--fw-600);
    margin-bottom: 5px;
  }

  .card-subtitle {
    color: var(--cool-gray);
    font-size: var(--fs-7);
  }

  .contact-list-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
  }

  .contact-link-2 {
    color: var(--cool-gray);
    font-size: var(--fs-7);
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .progress-item:not(:last-child) {
    margin-bottom: 25px;
  }

  .progress-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .progress-title {
    color: var(--onyx-2);
    font-size: var(--fs-8);
    font-weight: var(--fw-500);
  }

  .progress-data {
    color: var(--davys-gray);
    font-size: var(--fs-6);
  }

  .progress-bar {
    margin-top: 15px;
    width: 100%;
    height: 6px;
    background: var(--cultured);
    border-radius: var(--radius-6);
    overflow: hidden;
  }

  .progress {
    width: var(--width, 100%);
    height: 100%;
    background: var(--bg, var(--onyx));
  }

  .home .card-wrapper {
    display: grid;
    gap: 25px;
  }

  .home .task-card {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .home .task-card .card-icon.green {
    --background: var(--emerald_12);
    --color: var(--emerald);
  }

  .home .task-card .card-icon.blue {
    --background: var(--pacific-blue_12);
    --color: var(--pacific-blue);
  }

  .home .task-card .icon {
    font-size: 28px;
    color: var(--color);
  }

  .home .task-card .card-icon {
    background: var(--background);
    padding: 10px;
    border-radius: var(--radius-6);
  }

  .home .task-card .card-data {
    color: var(--onyx-2);
    font-size: var(--fs-3);
    font-weight: var(--fw-600);
    margin-bottom: 5px;
  }

  .home .task-card .card-text {
    color: var(--cool-gray);
    font-size: var(--fs-6);
  }

  .revenue-card .card-title {
    margin-bottom: 15px;
  }

  .card-price {
    color: var(--onyx);
    font-size: var(--fs-2);
    font-weight: var(--fw-600);
    margin-bottom: 12px;
  }

  .revenue-card .card-text {
    color: var(--cool-gray);
    font-size: var(--fs-6);
  }

  .revenue-item {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .revenue-item:first-child {
    margin-bottom: 10px;
  }

  .revenue-item .icon {
    font-size: 30px;
  }

  .revenue-item .icon.green {
    color: var(--emerald);
  }

  .revenue-item .icon.red {
    color: var(--imperial-red);
  }

  .revenue-item-data {
    color: var(--onyx);
    font-size: var(--fs-6);
    font-weight: var(--fw-600);
    margin-bottom: 5px;
  }

  .revenue-item-text {
    color: var(--cool-gray);
    font-size: var(--fs-9);
  }

  /*-----------------------------------*\
    #PROJECTS
  \*-----------------------------------*/

  .project-list {
    display: grid;
    gap: 25px;
  }

  .project-card .card-date {
    color: var(--cool-gray);
    font-size: var(--fs-9);
    font-weight: var(--fw-500);
    margin-bottom: 20px;
  }

  .project-card .card-title {
    color: var(--onyx);
    font-size: var(--fs-4);
    margin-bottom: 8px;
  }

  .project-card .card-title > a {
    color: inherit;
    transition: var(--transition);
  }

  .project-card .card-title > a:is(:hover, :focus) {
    color: var(--blue-ryb);
  }

  .project-card .card-badge {
    margin-bottom: 20px;
  }

  .project-card .card-text {
    color: var(--cool-gray);
    font-size: var(--fs-7);
    line-height: 1.7;
    margin-bottom: 15px;
  }

  .project-card .card-progress-box {
    margin-bottom: 15px;
  }

  .project-card .progress-title {
    font-weight: var(--fw-600);
  }

  .project-card .progress-data {
    color: var(--onyx);
    font-size: var(--fs-9);
    font-weight: var(--fw-600);
  }

  .project-card .progress-bar {
    margin-top: 10px;
  }

  .card-avatar-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .card-avatar-item > a {
    border: 2px solid var(--white);
    border-radius: 50%;
    overflow: hidden;
  }

  .card-avatar-item:not(:first-child) {
    margin-left: -15px;
  }

  /*-----------------------------------*\
    #TASKS
  \*-----------------------------------*/

  .tasks-item:not(:last-child) {
    margin-bottom: 10px;
  }

  .tasks .task-card {
    display: grid;
    gap: 15px;
  }

  .tasks .card-input {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  .tasks .card-input > input {
    margin-top: 2px;
    accent-color: var(--blue-ryb);
  }

  .tasks .card-input > input:checked {
    filter: drop-shadow(0 0 2px var(--blue-ryb));
  }

  .tasks .task-label {
    color: var(--davys-gray);
    font-size: var(--fs-9);
    font-weight: var(--fw-600);
    line-height: 1.5;
  }

  .tasks .card-meta-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }

  .tasks .meta-box {
    color: var(--davys-gray);
    font-size: var(--fs-9);
    font-weight: var(--fw-600);
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .tasks .btn-primary {
    margin-block: 25px;
    margin-inline: auto;
  }

  .tasks .btn-primary .spiner {
    padding: 6px;
    border: 2px solid var(--blue-ryb);
    border-top-color: transparent;
    border-radius: 50%;
    animation: rotate 0.75s linear infinite;
    display: none;
  }

  .tasks .btn-primary:is(:hover, :focus) .spiner {
    border-color: var(--white);
    border-top-color: transparent;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(1turn);
    }
  }

  .tasks .btn-primary.active .spiner {
    display: block;
  }

  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/

  .footer {
    background: var(--white);
    padding-block: 25px;
  }

  .footer-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .footer-link,
  .copyright {
    color: var(--davys-gray);
    font-size: var(--fs-6);
    line-height: 1.7;
  }

  .footer-link,
  .copyright-link {
    display: inline-block;
    transition: var(--transition);
  }

  :is(.footer-link, .copyright-link):is(:hover, :focus) {
    color: var(--blue-ryb);
  }

  .footer-item:not(:last-child)::after {
    content: "-";
    color: hsl(0, 0%, 80%);
    font-weight: var(--fw-600);
    margin-inline: 5px;
  }

  .copyright-link {
    color: inherit;
  }

  /*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

  /**
   * responsive for larger than 400px screen
   */

  @media (min-width: 400px) {
    /**
     * HOME
     */

    .revenue-item:first-child {
      margin-bottom: 0;
    }

    .revenue-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  /**
   * responsive for larger than 570px screen
   */

  @media (min-width: 570px) {
    /**
     * REUSED STYLE
     */
    .home-icon {
      display: none;
    }
    .container {
      max-width: 550px;
      margin-inline: auto;
    }

    /**
     * HEADER
     */

    .navbar-list,
    .user-action-list {
      padding-inline: 0;
    }

    /**
     * HOME
     */

    .card-price {
      --fs-2: 1.625rem;
    }

    /**
     * TASKS
     */

    .tasks .card-meta-list {
      justify-content: flex-end;
    }
  }

  /**
   * responsive for larger than 768px screen
   */

  @media (min-width: 768px) {
    /**
     * REUSED STYLE
     */

    .container {
      max-width: 700px;
    }

    /**
     * HOME
     */

    .progress-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }

    .progress-item:not(:last-child) {
      margin-bottom: 0;
    }

    .home .card-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }

  /**
   * responsive for larger than 992px screen
   */

  @media (min-width: 992px) {
    /**
     * REUSED STYLE
     */

    .container {
      max-width: 950px;
    }

    /**
     * HEADER
     */

    .menu-toggle-btn {
      display: none;
    }

    .header {
      height: unset;
      padding-block: 10px;
    }

    .header.active {
      height: unset;
    }

    .navbar {
      all: unset;
      flex-grow: 1;
    }

    .navbar .container {
      display: flex;
    }

    .navbar-list {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-inline: auto;
    }

    .user-action-list {
      flex-direction: row;
      gap: 20px;
    }

    /**
     * HOME
     */

    .article.container {
      padding-block: 35px;
      margin-top: 60px;
    }

    .home {
      grid-template-columns: 1.25fr 0.75fr 1fr;
    }

    .home .card-wrapper {
      grid-template-columns: 1fr;
    }

    .progress-list {
      align-items: flex-end;
    }

    /**
     * PROJECTS
     */

    .project-list {
      grid-template-columns: repeat(3, 1fr);
    }

    /**
     * TASKS
     */

    .tasks .task-card {
      grid-template-columns: 1fr 0.5fr 0.5fr 1fr 0.5fr;
      align-items: center;
    }

    /**
     * FOOTER
     */

    .footer-list {
      margin-bottom: 0;
    }

    .footer .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  /**
   * responsive for larger than 1200px screen
   */

  @media (min-width: 1200px) {
    /**
     * REUSED STYLE
     */

    .container {
      max-width: 1150px;
    }

    /**
     * HEADER
     */

    .navbar-list {
      gap: 25px;
    }

    /**
     * HOME
     */

    .card-price {
      --fs-2: 1.78rem;
    }

    /* notifications */
    .ctx-menu2 {
      background: var(--white);
      position: absolute;
      top: 65px;
      right: 150px;
      width: 440px;
      padding: 10px 5px;
      box-shadow: var(--shadow-1);
      border-radius: var(--radius-6);
      z-index: 9999999;
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}

/* //////pagination/// */
.pag-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ccc !important;
}

.pag-button:hover {
  background-color: #ccc;
}

.pag-button.active {
  background-color: #007bff !important;
  color: white !important;
}
